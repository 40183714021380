export { ROUTES, PUBLIC_AUTH_ROUTES } from './app/routes';

export const APP_TITLE = 'Lookify';
export const APP_DESCRIPTION =
	'Simplify your style routine with AI-powered outfit recommendations. Organize your wardrobe, pair new purchases with confidence, and enjoy the satisfaction of perfectly matched looks for any occasion.';

export const PROFILE_FACEBOOK_URL = 'https://www.facebook.com/profile.php?id=61563905564717';
export const PROFILE_INSTAGRAM_URL = 'https://www.instagram.com/lookify.ai/';
export const PROFILE_LINKEDIN_URL = 'https://www.linkedin.com/company/lookifyapp';

export const INITIAL_GENDER = 'feminine';

export const HEADER_HEIGHT = 72;
export const CONTENT_TOP_OFFSET = 24;

export const ALL_BODY = '';
export const CATEGORY_ORDER = [
	'hats',
	'bags',
	'jewellery',
	'accessories',
	'all_body',
	'outwear',
	'outerwear',
	'tops',
	'bottoms',
	'shoes',
];

// Minimum number of selections required for each category
export const MIN_SELECTION_COUNT = 2;
export const MIN_SELECTION_ALL_BODY_COUNT = 3;

export const MANDATORY_FEMININE_FIELDS_DEFAULT = ['all_body', 'tops', 'bottoms', 'shoes'];
export const MANDATORY_FIELDS_DEFAULT = ['tops', 'bottoms', 'shoes'];
export const MANDATORY_FEMININE_FIELDS_ALL_BODY = ['all_body', 'shoes'];

export const GENDER_CATEGORIES = {
	feminine: MANDATORY_FEMININE_FIELDS_DEFAULT,
	masculine: MANDATORY_FIELDS_DEFAULT,
};

export const PUBLIC_AUTH_ROUTES = {
	/** Basic login page. */
};

export const ROUTES = {
	...PUBLIC_AUTH_ROUTES,
	// Auth pages.
	notFoundPage: () => '*',
	home: () => '/',
	inspiration: () => '#inspiration',
	features: () => '#features',
	reviews: () => '#reviews',
	howItWorks: () => '#how-it-works',
	joinUs: () => '#join-us',
	whoWeAre: () => '#who-we-are',
	faq: () => '#faq',
	clothingSelection: () => `/demo/clothing-selection`,
	myWardrobe: () => '/demo/my-wardrobe',
	bestOutfits: () => '/demo/best-outfit',
};

export const HIDDEN_FOOTER_ROUTES = [ROUTES.myWardrobe(), ROUTES.bestOutfits(), ROUTES.clothingSelection()];

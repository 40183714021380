import React from 'react';
import styles from '@ui-modules/clothing/components/CategoryFilter/CategoryFilter.module.css';
import { BottomSheet, Button, DoubleChevronBottomIcon, DoubleChevronTopIcon, TrashIcon } from '@ui-kit';
import { useTranslation } from 'react-i18next';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import { useWindowSize } from 'react-use';
import ImageUploader from '../ImageUploader';
import { useProcessImagesMutation } from '@ui-modules/clothing/hooks/useProcessIImagesMutation';
import RCImage from 'rc-image';
import { useNotification } from '@hooks';
import type { ImageType } from 'react-images-uploading';

const SelectedClothesBottomSheet = ({
	isSheetOpen,
	expandedBottomSheet,
	toggleBottomSheet,
}: ISelectedClothesBottomSheetProps) => {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const { setUploadedClothes, uploadedClothes, removeUploadedImage, isCompressing, setIsCompressing } =
		useClothingActions();
	const { showError } = useNotification();

	const maxNumber = 25;

	const { mutateAsync: mutateProcessImages, isLoading } = useProcessImagesMutation({});

	const bottomSheetHeight = width <= 768 ? (isLoading || uploadedClothes?.length > 0 ? 180 : 76) : 128;
	const handleImagesChange = async (imageList: ImageType[]) => {
		if (uploadedClothes.length + imageList.length > maxNumber) {
			showError({
				title: t('Upload Limit Exceeded'),
				subtitle: t(`You can upload a maximum of {{maxNumber}} images.`, { maxNumber }),
			});
			return;
		}

		try {
			setIsCompressing(true);

			const processedImages = await mutateProcessImages({ images: imageList as unknown as Blob[] });

			setUploadedClothes(processedImages);
		} catch (error) {
			showError({
				title: t('Processing Error'),
				subtitle: t('There was an issue processing your images. Please try again.'),
			});
		} finally {
			setIsCompressing(false);
		}
	};

	return (
		<>
			<BottomSheet
				containerClassName={styles.categoryFilter__wardrobeBottomSheetContainer}
				disableDrag
				disableScrollLocking
				hasOverlay={false}
				initialSnap={0}
				isOpen={!isSheetOpen}
				snapPoints={[expandedBottomSheet ? bottomSheetHeight : 0]}
			>
				<div className={styles.categoryFilter__wardrobeSelectorContainer}>
					<div className={styles.categoryFilter__wardrobeSheet}>
						<ImageUploader
							images={uploadedClothes}
							imageUploaderTitle={t('Upload Clothing')}
							isLoading={isLoading || isCompressing}
							renderImage={({ image, index }) => {
								return (
									<div className={styles.imageUploader__imageItem} key={index}>
										<RCImage
											alt=""
											height={90}
											preview={false}
											src={image.image_url}
											onError={() => console.error(`Failed to load image: ${image.image_url}`)}
										/>
										<Button
											className={styles.imageUploader__trash}
											icon={<TrashIcon fill="var(--color-error)" />}
											size="extra_small"
											variant="tertiary"
											onClick={async () => {
												removeUploadedImage(index);
											}}
										/>
									</div>
								);
							}}
							separator
							onImagesChange={handleImagesChange}
						/>
					</div>
					{expandedBottomSheet && (
						<span
							className={styles.categoryFilter__collapse}
							role="button"
							tabIndex={0}
							onClick={toggleBottomSheet}
							onKeyPress={toggleBottomSheet}
						>
							<DoubleChevronBottomIcon color="#fff" />
						</span>
					)}
				</div>
			</BottomSheet>
			{!expandedBottomSheet && (
				<span
					className={styles.categoryFilter__expand}
					role="button"
					tabIndex={0}
					onClick={toggleBottomSheet}
					onKeyPress={toggleBottomSheet}
				>
					<DoubleChevronTopIcon color="black" />
				</span>
			)}
		</>
	);
};

export interface ISelectedClothesBottomSheetProps {
	isSheetOpen: boolean;
	expandedBottomSheet: boolean;
	toggleBottomSheet: () => void;
}

export default SelectedClothesBottomSheet;

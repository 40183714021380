import { useEffect } from 'react';
import { MIN_SELECTION_COUNT, MIN_SELECTION_ALL_BODY_COUNT, GENDER_CATEGORIES } from '@constants';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import { createCategoryCounter } from '@ui-modules/clothing/utils/createCategoryCounter';

export const useClothingValidation = () => {
	const { selectedClothes, uploadedClothes, gender, setCanProceedToWardrobe, canProceedToWardrobe, wardrobeItems } =
		useClothingActions();

	useEffect(() => {
		try {
			// Combine and filter clothes by gender-allowed clothing
			const allClothes = [...(selectedClothes || []), ...uploadedClothes];
			const allowedCategories = GENDER_CATEGORIES[gender];
			const filteredClothes = allClothes.filter((item) => allowedCategories.includes(item.category.toLowerCase()));

			const categoryCounts = createCategoryCounter(filteredClothes);

			// Feminine validation
			if (gender === 'feminine') {
				const hasAllBodyPath =
					(categoryCounts.all_body || 0) >= MIN_SELECTION_ALL_BODY_COUNT &&
					(categoryCounts.shoes || 0) >= MIN_SELECTION_COUNT;

				const hasTopsBottomsPath =
					(categoryCounts.tops || 0) >= MIN_SELECTION_COUNT &&
					(categoryCounts.bottoms || 0) >= MIN_SELECTION_COUNT &&
					(categoryCounts.shoes || 0) >= MIN_SELECTION_COUNT;

				setCanProceedToWardrobe(hasAllBodyPath || hasTopsBottomsPath);
			}
			// Masculine validation
			else {
				const isValid =
					(categoryCounts.tops || 0) >= MIN_SELECTION_COUNT &&
					(categoryCounts.bottoms || 0) >= MIN_SELECTION_COUNT &&
					(categoryCounts.shoes || 0) >= MIN_SELECTION_COUNT;

				setCanProceedToWardrobe(isValid);
			}
		} catch (error) {
			setCanProceedToWardrobe(false);
		}
	}, [gender, selectedClothes, uploadedClothes]);

	return { isValid: canProceedToWardrobe };
};

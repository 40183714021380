import { useMemo } from 'react';
import {
	MANDATORY_FEMININE_FIELDS_ALL_BODY,
	MANDATORY_FEMININE_FIELDS_DEFAULT,
	MANDATORY_FIELDS_DEFAULT,
} from '@constants';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';

export const useFindMandatoryCategories = ({ selectedCounts }: TUseFindMandatoryCategoriesParams): string[] => {
	const { gender } = useClothingActions();

	return useMemo(() => {
		if (gender === 'masculine') {
			// For masculine users, default mandatory fields are used.
			return MANDATORY_FIELDS_DEFAULT; // e.g. ['tops', 'bottoms', 'shoes']
		} else if (gender === 'feminine') {
			// For feminine users, if at least one all_body item is selected, then use the all_body option.
			if ((selectedCounts['all_body'] || 0) >= 1) {
				return MANDATORY_FEMININE_FIELDS_ALL_BODY; // e.g. ['all_body', 'shoes']
			}
			if ((selectedCounts['tops'] || selectedCounts['bottoms']) >= 1) {
				return MANDATORY_FIELDS_DEFAULT; // e.g. ['all_body', 'shoes']
			}
			return MANDATORY_FEMININE_FIELDS_DEFAULT; // e.g. ['tops', 'bottoms', 'shoes']
		}

		return [];
	}, [gender, selectedCounts]);
};

export type TUseFindMandatoryCategoriesParams = {
	selectedCounts: Record<string, number>;
};

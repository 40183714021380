import { useScreenSize, useService, useTranslation } from '@hooks';
import { capitalize, clsx } from '@utils';
import styles from './ClothingSelectorCta.module.css';
import { ArrowRightIcon, Button, WardrobeIcon } from '@ui-kit';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useClothingValidation } from '@ui-modules/clothing/hooks/useClothingValidation';
import { groupClothingItems } from '@ui-modules/clothing/utils/groupByCategory';
import { ROUTES } from '@constants';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import type { FormikProps } from 'formik';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';

const ClothingSelectorCta = ({
	isBottomSheetExpanded,
	formProps,
	isCategoriesSheetOpen,
}: IClothingSelectorCtaProps) => {
	const { t } = useTranslation();
	const { selectedClothes, gender, uploadedClothes, isCompressing, setWardrobeItems } = useClothingActions();
	const { width } = useScreenSize();

	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const { isValid } = useClothingValidation();

	const onMyWardrobeClick = async () => {
		const clothes = [...(selectedClothes || []), ...uploadedClothes];
		const groupedClothes = groupClothingItems(clothes);

		setWardrobeItems(groupedClothes);

		analytics.trackEvent('Added_To_Wardrobe', {
			outfits_count: clothes?.length,
			outfits: clothes,
			gender: gender,
			hardcoded_addition_count: selectedClothes?.length || 0,
			uploaded_addition_count: uploadedClothes?.length || 0,
		});

		navigate(ROUTES.myWardrobe());
	};

	return (
		<>
			<div
				className={clsx(
					styles.clothingSelection__wrapper,
					isBottomSheetExpanded && styles.clothingSelection__wrapper_expanded,
					(uploadedClothes?.length > 0 || isCompressing) &&
						isBottomSheetExpanded &&
						styles.clothingSelection__wrapper_uploaded,
				)}
				data-sheet-open={String(isCategoriesSheetOpen)}
			>
				<Button
					className={styles.howItWorks__clickable}
					size={'large'}
					title={t('How it works?')}
					trackingName={'tutorial'}
					variant="link"
					onClick={() => {
						window.open('https://www.youtube.com/watch?v=EFEp8tERLQM', '_blank');
					}}
				/>
				<Button
					badge={
						uploadedClothes?.length + selectedClothes?.length !== 0 && (
							<div className={styles.clothingSelectorCTA__itemCounter}>
								{uploadedClothes?.length + selectedClothes?.length}
							</div>
						)
					}
					className={styles.clothingSelection__clickable}
					disabled={!isValid || isCompressing}
					icon={<WardrobeIcon className={styles.clothingSelectorCTA__wardrobeIcon} />}
					size={'large'}
					title={width > 457 ? t('My Wardrobe') : ''}
					variant="secondary"
					onClick={onMyWardrobeClick}
				/>

				<Button
					className={styles.clothingSelection__clickable}
					icon={<ArrowRightIcon fill={'#3F4647'} height={24} width={24} />}
					iconPosition={'right'}
					size="large"
					title={capitalize(formProps.values.nextAvailableCategory?.replace(/_/g, ' '))}
					variant="secondary"
					onClick={() => formProps.setFieldValue('activeCategoryName', formProps.values.nextAvailableCategory)}
				/>
			</div>
		</>
	);
};

export interface IClothingSelectorCtaProps {
	isBottomSheetExpanded: boolean;
	isCategoriesSheetOpen: boolean;
	formProps: FormikProps<TDemoFormValues>;
}

export default ClothingSelectorCta;

import React, { useEffect, useState } from 'react';
import { useTranslation } from '@hooks';
import { clsx } from '@utils';
import { Modal } from '@ui-kit';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import { useToggle } from 'react-use';

import styles from './GenderSelector.module.css';
import type { TGender } from '@typings';
import type { FormikProps } from 'formik';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';

export interface IGenderSelectorProps {
	setGender: (gender: TGender) => void;
	gender: TGender;
	formProps: FormikProps<TDemoFormValues>;
	isSheetOpen: boolean;
	setIsSheetOpen: (isSheetOpen: boolean) => void;
}

const GenderSelector: React.FC<IGenderSelectorProps> = ({
	gender,
	setGender,
	formProps,
	isSheetOpen,
	setIsSheetOpen,
}) => {
	const { t } = useTranslation();
	const { setFieldValue, values } = formProps;
	const { resetState } = useClothingActions();

	const [showDialog, toggleShowDialog] = useToggle(false);
	const [pendingGender, setPendingGender] = useState<TGender | null>(null);

	useEffect(() => {
		if (isSheetOpen && showDialog) {
			setIsSheetOpen(false);
		}
	}, [isSheetOpen, showDialog]);

	const onGenderToggle = (selectedGender: TGender) => {
		if (values.selectedClothes?.length && selectedGender !== gender) {
			setPendingGender(selectedGender);
			toggleShowDialog();
		} else {
			setGender(selectedGender);
		}
	};

	const onDiscard = () => {
		if (pendingGender) {
			setGender(pendingGender);
			resetState(pendingGender);
		}

		setFieldValue('selectedClothes', []);
		setFieldValue('uploadedClothes', []); // If using Formik for uploaded files

		setPendingGender(null);
		toggleShowDialog();
	};

	return (
		<>
			<div className={styles.gender__container}>
				<button
					className={clsx(styles.pressable_feminine, gender === 'masculine' && styles.pressable_disabled)}
					type="button"
					onClick={() => onGenderToggle('feminine')}
					onTouchStart={() => onGenderToggle('feminine')}
				>
					<p className={clsx(styles.pressable__title, gender === 'masculine' && styles.pressable__title_disabled)}>
						{t('Feminine')}
					</p>
				</button>

				<button
					className={clsx(styles.pressable_masculine, gender === 'feminine' && styles.pressable_disabled)}
					type="button"
					onClick={() => onGenderToggle('masculine')}
					onTouchStart={() => onGenderToggle('feminine')}
				>
					<p className={clsx(styles.pressable__title, gender === 'feminine' && styles.pressable__title_disabled)}>
						{t('Masculine')}
					</p>
				</button>
			</div>

			{/* Confirmation Modal */}
			<Modal
				cancelTitle={t('Yes, switch')}
				confirmTitle={t('No, stay with my selection')}
				subTitle={t(
					'Switching the gender preference will clear your current selections. Do you still want to proceed?',
				)}
				title={t('Switching Styles?')}
				variant="medium"
				visible={showDialog}
				onCancel={onDiscard}
				onConfirm={() => {
					setPendingGender(null);
					toggleShowDialog();
				}}
			/>
		</>
	);
};

export default GenderSelector;

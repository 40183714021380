import styles from './ClothingItemCard.module.css';
import { Checkbox, TickIcon } from '@ui-kit';
import React, { useState } from 'react';
import type { IClothing } from '@typings';
import { clsx, noop } from '@utils';
import Image from 'rc-image';

const ClothingItemCard = ({
	item,
	isSelected,
	onSelect,
	imageClassName,
	imageWrapperClassName,
}: IClothingCardProps) => {
	const [loaded, setLoaded] = useState(false);

	return (
		<div
			className={clsx(styles.clothingItemCard__item, imageWrapperClassName)}
			onClick={() => onSelect(item)}
			onKeyPress={() => onSelect(item)}
		>
			<Image
				alt={item.name}
				className={clsx(styles.clothingItemCard__itemImage, imageClassName)}
				loading="lazy"
				preview={false}
				src={item.image_url}
				onError={() => console.error(`Failed to load image: ${item.image_url}`)}
				onLoad={() => setLoaded(true)}
			/>
			{loaded && (
				<div className={styles.clothingItemCard__footer}>
					<Checkbox
						checkedClassName={styles.clothingItemCard__checkboxIcon}
						className={styles.clothingItemCard__checkbox}
						disabled
						icon={<TickIcon height={15} stroke={'var(--color-primary-100)'} width={18} />}
						size={'medium'}
						value={isSelected}
						onChange={noop}
					/>
				</div>
			)}
		</div>
	);
};

export interface IClothingCardProps {
	isSelected: boolean;
	item: IClothing;
	onSelect: (item: IClothing) => void;
	imageClassName?: string;
	imageWrapperClassName?: string;
}

export default ClothingItemCard;

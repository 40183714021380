import type { IClothing } from '@typings';

export const groupClothingItems = (items: IClothing[]): Record<string, (IClothing & { isSelected: boolean })[]> => {
	return items.reduce((acc, item) => {
		if (!acc[item.category]) {
			acc[item.category] = [];
		}
		acc[item.category].push({ ...item, isSelected: true });
		return acc;
	}, {} as Record<string, (IClothing & { isSelected: boolean })[]>);
};

// useWardrobeValidation.ts
import { useEffect } from 'react';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import { validateWardrobeItems } from '../utils/wardrobeValidation';
import type { IWardrobeItems } from '@typings';

export const useWardrobeValidation = () => {
	const { gender, setCanProceedToWardrobe, canProceedToWardrobe, wardrobeItems } = useClothingActions();

	useEffect(() => {
		try {
			const isValid = validateWardrobeItems(wardrobeItems as IWardrobeItems, gender);
			setCanProceedToWardrobe(isValid);
		} catch (error) {
			setCanProceedToWardrobe(false);
		}
	}, [gender, wardrobeItems, setCanProceedToWardrobe]);

	return { isValid: canProceedToWardrobe };
};

import React from 'react';
import { LoadingSkeleton } from '@ui-kit';
import CategoryRow from '../CategoryRow';
import styles from './CategoryList.module.css';
import type { ICategory } from '@typings';
import { MIN_SELECTION_ALL_BODY_COUNT, MIN_SELECTION_COUNT } from '@constants';
import { useFindMandatoryCategories } from '@ui-modules/clothing/hooks/useFindMandatoryCategories';

const CategoryList: React.FC<ICategoryListProps> = ({
	categories,
	activeCategoryName,
	onCategorySelect,
	isLoading,
	selectedCounts,
}) => {
	const mandatoryCategories = useFindMandatoryCategories({ selectedCounts });

	if (isLoading) {
		return (
			<div className={styles.categoryFilter__activityIndicatorWrapper}>
				<LoadingSkeleton height={54} lines={1} width="100%" />
			</div>
		);
	}

	return (
		<>
			{categories?.map((category) => {
				const catName = category.name.toLowerCase();
				const isMandatory = mandatoryCategories.includes(catName);
				const maxCount = isMandatory
					? catName === 'all_body'
						? MIN_SELECTION_ALL_BODY_COUNT
						: MIN_SELECTION_COUNT
					: undefined;

				return (
					<CategoryRow
						category={category}
						disabled={false}
						isActive={category.name === activeCategoryName}
						isMandatory={isMandatory}
						key={category.name}
						maxCount={maxCount as number}
						selectedCount={selectedCounts[catName] || 0}
						onCategorySelect={onCategorySelect}
					/>
				);
			})}
		</>
	);
};

export interface ICategoryListProps {
	categories: ICategory[] | undefined;
	activeCategoryName?: string | null;
	onCategorySelect: (categoryName: string) => void;
	selectedCounts: Record<string, number>;
	isLoading?: boolean;
}

export default CategoryList;

import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { TReviewForm } from '../components/ReviewForm';
import { useTranslation } from 'react-i18next';

export const useAddGroupReviewMutation = () => {
	const analytics = useService('AnalyticsService');
	const bestOutfitConvex = useService('BestOutfitConvex');
	const { showUnknownError, showSuccess } = useNotification();

	const { user } = useMe();
	const { t } = useTranslation();

	return useMutation<unknown, Error, TReviewForm & { groupUrls: string[]; id: string }>(
		['rateBestOutfit', user.id],
		async ({ id, groupUrls, review, compatibility, intuitiveness, stylePreferences, usagePurpose }) => {
			await bestOutfitConvex.addGroupReview({
				id,
				groupUrls,
				review,
				compatibility,
				intuitiveness,
				stylePreferences,
				usagePurpose,
			});
			analytics.trackEvent('Best_Outfits_Review', {
				id,
				group_urls: groupUrls,
				review,
				compatibility,
				intuitiveness,
				style_preferences: stylePreferences,
				usage_purpose: usagePurpose,
			});
		},

		{
			onSuccess: () => {
				showSuccess({ title: t('Review submitted successfully!') });
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};

import React from 'react';
import styles from './WardrobePage.module.css';
import { useTranslation } from 'react-i18next';
import type { IClothing } from '@typings';

const CursorContent = ({ selectedItems }: ICursorContentProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.tooltipContent}>
			<div className={styles.tooltipSection}>{t('Generates AI-curated outfits based on your selected items')}</div>

			<div className={styles.tooltipSection}>
				{selectedItems.length < 15 && (
					<>
						<span className={styles.tooltipHighlight}>
							{t('You have selected')} {selectedItems.length}{' '}
							{t('item{{plural}}', { plural: selectedItems.length === 1 ? '' : 's' })}.
						</span>
						<span>
							{t('Fewer than 15 items may limit outfit suggestions.')} {t('We recommend selecting')}
						</span>
						<strong> {t('15-20 items')}</strong> {t('for optimal results')}.
					</>
				)}

				{selectedItems.length >= 15 && selectedItems.length <= 20 && (
					<>
						<span className={styles.tooltipSuccess}>
							{t('Perfect selection of')} {selectedItems.length} {t('items')}!
						</span>
						<span>
							{t('This quantity ensures')}
							<strong> {t('fast processing')} </strong>
							{t('and')} <strong>{t('diverse combinations')}</strong>.
						</span>
					</>
				)}

				{selectedItems.length > 20 && (
					<>
						<span className={styles.tooltipWarning}>{t('Extended processing time')}</span>
						<span>
							{t('You have selected')} {selectedItems.length} {t('items')}. {t('Larger collections may require')}{' '}
							<strong>{t('additional time')}</strong> {t('to generate')}.
						</span>
					</>
				)}
			</div>

			<div className={styles.tooltipFooter}>
				<div>
					{t('Recommended range:')} <strong>15-20 {t('items')}</strong>
				</div>
				<div>
					{t('Flexible upload:')} {t('Any quantity accepted')}
				</div>
			</div>
		</div>
	);
};

export interface ICursorContentProps {
	selectedItems: IClothing[];
}
export default CursorContent;

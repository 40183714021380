import { useMe, useMutation, useNotification, useService } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import { extractBackgroundRemovedImagesNames } from '@ui-modules/clothing/utils/extractBackgroundRemovedImagesNames';
import { convertDataToImageList } from '@ui-modules/clothing/utils/convertDataToImageList';
import type { ImageType } from 'react-images-uploading';
import type { IClothing } from '@typings';

export const useProcessImagesMutation = (options?: MutationOptions<IClothing[], Error, { images: ImageType[] }>) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();

	return useMutation<IClothing[], Error, { images: Blob[] }>(
		['processImages', user?.id],
		async ({ images }: { images: Blob[] }) => {
			// Step 1: Remove background from images
			const backgroundRemovedImages = await api.media.removeBackground(images, user.id as string);
			// Step 2: Extract image names after background removal
			const extractedImageNames = extractBackgroundRemovedImagesNames(backgroundRemovedImages);

			// Step 3: Classify images
			const classifiedData = await api.media.classifyImages(extractedImageNames, user.id as string);
			// Step 4: Convert classified data to an image list using getClassifiedImage
			return await convertDataToImageList(
				classifiedData,
				user.id,
				api.media.getClassifiedImage.bind(api.media),
				backgroundRemovedImages,
			);
		},
		{
			onSuccess: (response, variables, context) => {
				// Optional: Invalidate queries if needed
				// reactQuery.queryClient.invalidateQueries(['someQueryKeyIfNeeded']);
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
			cacheTime: 0,
		},
	);
};

import React from 'react';
import { motion } from 'framer-motion';
import type { Variants } from 'framer-motion';

const containerVariants: Variants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			staggerChildren: 0.001,
			delayChildren: 0.3,
		},
	},
};

const characterVariants: Variants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.1,
		},
	},
};

const AnimatedText: React.FC<IAnimatedTextProps> = ({ text, className }) => {
	// Split into words first
	const words = text.split(' ');
	let characterIndex = 0;

	return (
		<div style={{ wordBreak: 'break-word', display: 'inline-block' }}>
			<motion.div
				className={className}
				initial="hidden"
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: '0.2em',
				}}
				variants={containerVariants}
				viewport={{ once: true, amount: 0.8 }}
				whileInView="visible"
			>
				{words.map((word, wordIndex) => (
					<div
						key={wordIndex}
						style={{
							display: 'inline-flex',
							flexShrink: 0,
						}}
					>
						{word.split('').map((char) => {
							characterIndex++;
							return (
								<motion.span key={characterIndex} style={{ display: 'inline-block' }} variants={characterVariants}>
									{char}
								</motion.span>
							);
						})}
					</div>
				))}
			</motion.div>
		</div>
	);
};

interface IAnimatedTextProps {
	text: string;
	className?: string;
}

export default AnimatedText;

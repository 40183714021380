import { useService, useMe, useNotification } from '@hooks';
import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useBestOutfitMutation } from '@ui-modules/bestOutfit/hooks/useBestOutfitMutation';
import { v4 as uuidv4 } from 'uuid';
import {
	extractBackgroundRemovedImageNamesWithoutFormat,
	extractBackgroundRemovedImagesNames,
} from '@ui-modules/clothing/utils/extractBackgroundRemovedImagesNames';

export const useGetBestOutfitsMutation = (
	mutationOptions?: UseMutationOptions<TUseGetBestOutfitsMutationData, Error, TUseGetBestOutfitsMutationVariables>,
) => {
	const api = useService('ApiService');
	const { user } = useMe();

	const { t } = useTranslation();
	const { showWarning } = useNotification();

	const { mutate } = useBestOutfitMutation();

	return useMutation<TUseGetBestOutfitsMutationData, Error, TUseGetBestOutfitsMutationVariables>(
		['wardrobe.getBestOutfits'],
		async ({ imageUrls }: TUseGetBestOutfitsMutationVariables) => {
			if (!user.id) throw new Error('User ID is required');
			const result = await api.clothing.getBestOutfits(
				user.id as string,
				extractBackgroundRemovedImageNamesWithoutFormat(imageUrls),
			);

			result?.bestOutfitUrls?.map((outfit) => {
				mutate({
					rating: 0,
					outfit_url: outfit,
					user_id: user.id as string,
					creationTime: new Date().toISOString(),
					id: uuidv4(),
				});
			});

			return { ...result };
		},
		{
			...mutationOptions,
			cacheTime: 0,
			onError: (error) => {
				if (!error) return null;

				if (error instanceof AxiosError && error?.response?.status === 404) {
					showWarning({ title: t('No matches found. Please select more outfits and try again!') });
				}
			},
		},
	);
};

export type TUseGetBestOutfitsMutationData = {
	bestOutfitUrls: string[];
};

export type TUseGetBestOutfitsMutationVariables = {
	imageUrls: string[];
};

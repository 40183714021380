import React from 'react';
import { clsx } from '@utils';
import styles from './CategoryRow.module.css';
import type { ICategory } from '@typings';

export interface ICategoryRowProps {
	category: ICategory;
	isActive: boolean;
	onCategorySelect: (categoryName: ICategory['name']) => void;
	disabled?: boolean;
	selectedCount?: number;
	maxCount?: number;
	isMandatory?: boolean;
}

const CategoryRow: React.FC<ICategoryRowProps> = ({
	category,
	isActive,
	onCategorySelect,
	disabled = false,
	selectedCount = 0,
	maxCount,
	isMandatory = false,
}) => {
	// Determine if the category has met validation requirements
	const hasValidSelection = maxCount ? selectedCount >= maxCount : selectedCount > 0;

	return (
		<button
			className={clsx(styles.row, isActive && styles.active, disabled && styles.disabled)}
			disabled={disabled}
			onClick={() => !disabled && onCategorySelect(category.name)}
		>
			{isMandatory && (
				<span
					className={clsx(
						styles.mandatoryBadge,
						hasValidSelection ? styles.mandatoryBadgeValid : styles.mandatoryBadgeDisabled,
					)}
				>
					*
				</span>
			)}

			<div className={styles.info}>
				<span className={clsx(styles.name, isActive && styles.activeName)}>{category.title}</span>
			</div>
			<div className={clsx(styles.counter, hasValidSelection && styles.counter_eligible)}>
				{maxCount ? `${selectedCount} / ${maxCount}` : selectedCount}
			</div>
		</button>
	);
};

export default CategoryRow;

import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { IClothing, TGender } from '@typings';

export const useGetClothesQuery = <T = TUseGetClothesByIdQueryData>(
	params: TUseGetClothesQuery,
	queryOptions?: UseQueryOptions<TUseGetClothesByIdQueryData, Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TUseGetClothesByIdQueryData, Error, T>(
		['getClothesById', params.name, params.gender],
		() =>
			api.clothing.getClothesById(
				params as {
					name: IClothing['name'];
					gender: TGender;
				},
			),
		{
			cacheTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			staleTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			enabled: !!params?.name,
			...queryOptions,
		},
	);
};

type TUseGetClothesQuery = { name: IClothing['name'] | null; gender: TGender };
type TUseGetClothesByIdQueryData = IClothing[] | undefined;

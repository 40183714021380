import React, { useEffect } from 'react';

import { useTranslation, useScreenSize } from '@hooks';
import { Button, Modal } from '@ui-kit';
import { useGetClothesQuery } from '@ui-modules/clothing/hooks/useGetClothesQuery';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import { useToggle } from 'react-use';

import styles from './ClothingSelector.module.css';
import { MobileList } from '@ui-modules/clothing/components/ClothingSelector/MobileList';
import { useClothingSelector } from '@ui-modules/clothing/hooks/useClothingSelector';

import type { Dispatch, SetStateAction } from 'react';
import type { FormikProps } from 'formik';
import type { ICategory, IClothing } from '@typings';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';
import { DesktopGrid } from './DesktopGrid';
import { useGetCategoriesQuery } from '@ui-modules/clothing/hooks/useGetCategoriesQuery';

const ClothingSelector: React.FC<IClothingSelectorProps> = ({
	formProps,
	isSheetOpen,
	setIsSheetOpen,
	selectedCounts,
}) => {
	const { t } = useTranslation();
	const { width } = useScreenSize();
	const { values } = formProps;
	const { setSelectedClothes } = useClothingActions();

	const [showDialog, toggleShowDialog] = useToggle(false);

	const { data: categories, isFetched } = useGetCategoriesQuery({
		gender: formProps.values.gender,
	});

	const { data: clothes, isLoading } = useGetClothesQuery(
		{
			name: formProps.values.activeCategoryName || (categories?.[0].name as string),
			gender: formProps.values.gender,
		},
		{
			enabled: isFetched,
		},
	);

	useEffect(() => {
		if (values?.selectedClothes?.length) {
			setSelectedClothes(values.selectedClothes);
		}
	}, [values?.selectedClothes, setSelectedClothes]);

	const { onSelect, onDiscard } = useClothingSelector({ formProps });

	return (
		<div className={styles.clothingSelector__container}>
			<div className={styles.clothingSelector__titleContainer}>
				<div className={styles.clothingSelector__titleWrapper}>
					<h4>{t('Select clothing')}</h4>
					<Button
						disabled={values.selectedClothes.length === 0}
						size={width > 768 ? 'medium' : 'small'}
						title={t('Unselect all')}
						titleClassName={styles.clothingSelector__title}
						variant="secondary"
						onClick={toggleShowDialog}
					/>
				</div>
			</div>

			{width > 768 ? (
				<div className={styles.clothingSelector__gridContainer}>
					<DesktopGrid
						clothes={clothes as IClothing[]}
						isLoading={isLoading}
						selectedClothes={values?.selectedClothes}
						onSelect={onSelect}
					/>
				</div>
			) : (
				<MobileList
					categories={categories as ICategory[]}
					clothes={clothes as IClothing[]}
					formProps={formProps}
					isLoading={isLoading}
					isSheetOpen={isSheetOpen}
					selectedClothes={values?.selectedClothes}
					selectedCounts={selectedCounts}
					setIsSheetOpen={setIsSheetOpen}
					onSelect={onSelect}
				/>
			)}

			<Modal
				cancelTitle={t('Yes, remove')}
				confirmTitle={t('No, keep them')}
				subTitle={t('These clothes have been part of your style journey. Are you sure you want to say goodbye?')}
				title={t('Letting Go of Style')}
				variant="medium"
				visible={showDialog}
				onCancel={() => {
					onDiscard();
					toggleShowDialog();
				}}
				onConfirm={toggleShowDialog}
			/>
		</div>
	);
};

export interface IClothingSelectorProps {
	formProps: FormikProps<TDemoFormValues>;
	isSheetOpen: boolean;
	selectedCounts: Record<string, number>;
	setIsSheetOpen: Dispatch<SetStateAction<boolean>>;
}

export default ClothingSelector;

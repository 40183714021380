// wardrobeValidationUtils.ts
import { MIN_SELECTION_COUNT, MIN_SELECTION_ALL_BODY_COUNT, GENDER_CATEGORIES } from '@constants';
import { createCategoryCounter } from '@ui-modules/clothing/utils/createCategoryCounter';
import type { IWardrobeItems } from '@typings';

export const validateWardrobeItems = (wardrobeItems: IWardrobeItems, gender: 'masculine' | 'feminine'): boolean => {
	// Flatten selected items from wardrobe structure
	const selectedItems = Object.values(wardrobeItems)
		.flat()
		.filter((item) => item.isSelected);

	const allowedCategories = GENDER_CATEGORIES[gender];
	const filteredItems = selectedItems.filter((item) => allowedCategories.includes(item.category.toLowerCase()));

	const categoryCounts = createCategoryCounter(filteredItems);

	if (gender === 'feminine') {
		const hasAllBodyPath =
			(categoryCounts.all_body || 0) >= MIN_SELECTION_ALL_BODY_COUNT &&
			(categoryCounts.shoes || 0) >= MIN_SELECTION_COUNT;

		const hasTopsBottomsPath =
			(categoryCounts.tops || 0) >= MIN_SELECTION_COUNT &&
			(categoryCounts.bottoms || 0) >= MIN_SELECTION_COUNT &&
			(categoryCounts.shoes || 0) >= MIN_SELECTION_COUNT;

		return hasAllBodyPath || hasTopsBottomsPath;
	}

	// Masculine validation
	return (
		(categoryCounts.tops || 0) >= MIN_SELECTION_COUNT &&
		(categoryCounts.bottoms || 0) >= MIN_SELECTION_COUNT &&
		(categoryCounts.shoes || 0) >= MIN_SELECTION_COUNT
	);
};

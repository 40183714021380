import styles from './ClothingSelector.module.css';
import type { GridComponents } from 'react-virtuoso';
import React from 'react';
export const gridComponents: GridComponents = {
	List: ({ style, children, ...props }) => (
		<div {...props} className={styles.clothingSelector__gridList}>
			{children}
		</div>
	),
	Item: ({ children, ...props }) => (
		<div {...props} className={styles.clothingSelector__gridListItem}>
			{children}
		</div>
	),
	Footer: () => <div style={{ height: '100px' }} />,
};

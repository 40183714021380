import React from 'react';

import { useNavigate } from '@hooks';

import styles from './HeaderDemoTooltip.module.css';
import Tooltip from '../Tooltip';
import { ROUTES } from '@constants';

const HeaderDemoTooltip = ({ showTooltip, setShowTooltip, title, description }: IProfileHeaderTooltipProps) => {
	const navigate = useNavigate();

	const handleTooltipClick = () => {
		navigate(ROUTES.clothingSelection());
		setShowTooltip(false);
	};

	return (
		<>
			{showTooltip && (
				<Tooltip
					className={styles.tooltip}
					classNameArrow={styles.tooltip__arrow}
					clickable
					closeEvents={{
						mouseleave: false,
						blur: false,
						dblclick: false,
						mouseup: false,
						mouseout: false,
						click: false,
					}}
					data-tooltip-id="demo-tooltip"
					globalCloseEvents={{
						clickOutsideAnchor: true,
					}}
					id="demo-tooltip"
					isOpen={showTooltip}
					openEvents={{
						mouseenter: false,
						focus: false,
						click: false,
						dblclick: false,
						mousedown: false,
					}}
					place="bottom-end"
					setIsOpen={setShowTooltip}
				>
					<button className={styles.tooltipContent} onClick={handleTooltipClick}>
						<p className={styles.tooltipTitle}>{title}</p>
						<p className={styles.tooltipDescription}>{description}</p>
					</button>
				</Tooltip>
			)}
		</>
	);
};

export interface IProfileHeaderTooltipProps {
	showTooltip: boolean;
	setShowTooltip: (showTooltip: boolean) => void;
	title: string;
	description: string;
}

export default HeaderDemoTooltip;

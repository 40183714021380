export const extractBackgroundRemovedImagesNames = (urls: string[]): string[] => {
	return urls.map((url) => {
		const fileName = url.split('/').pop(); // Get the last part of the URL
		return fileName?.replace('_bg_removed.jpg', '') || ''; // Remove the suffix
	});
};

export const extractBackgroundRemovedImageNamesWithoutFormat = (urls: string[]): string[] => {
	return urls.map((url) => {
		const fileName = url.split('/').pop(); // Get the last part of the URL
		return fileName?.replace('_bg_removed', '') || ''; // Remove the suffix
	});
};

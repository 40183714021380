import { object, string, array } from 'yup';
import type { TFunction } from 'i18next';

export const bestOutfitReviewSchemas = (t: TFunction) =>
	object({
		review: string().max(1200, t('Review cannot be more than 1200 characters')).notRequired(),
		usagePurpose: array().min(1, t('Select at least one usage purpose')),
		stylePreferences: array().min(1, t('Select at least style preference')),
		compatibility: string().required(t('Select at least one compatibility option')),
		intuitiveness: string().required(t('Select at least one intuitiveness option')),
	});

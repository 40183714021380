import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useTranslation, useScreenSize } from '@hooks';
import { INITIAL_GENDER, ROUTES } from '@constants';
import { noop } from '@utils';
import CategoryFilter from '@ui-modules/clothing/components/CategoryFilter';
import ClothingSelector from '../../../ui-modules/clothing/components/ClothingSelector';
import SelectedClothesBottomSheet from '@ui-modules/clothing/components/CategoryFilter/SelectedClothesBottomSheet';
import { FormPageLayout, Page } from '../../components';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import styles from './ClothingSelectionPage.module.css';
import type { ICategory, IClothing, TGender } from '@typings';
import { clothingSelectionSchema } from '@schemas';
import { DemoPageStructuredData } from './DemoPageStructuredData';
import { useGetCategoriesQuery } from '@ui-modules/clothing/hooks/useGetCategoriesQuery';
import { createCategoryCounter } from '@ui-modules/clothing/utils/createCategoryCounter';
import { useLockBodyScroll, useToggle } from 'react-use';
import ClothingSelectorCta from '../../../ui-modules/clothing/components/ClothingSelectorCta';

const ClothingSelectionPage: React.FC = () => {
	const { t } = useTranslation();
	const { width } = useScreenSize();
	const { selectedClothes, gender } = useClothingActions();

	const [isSheetOpen, setIsSheetOpen] = useToggle(false);
	const [expandedBottomSheet, toggleBottomSheet] = useToggle(true);
	const { data: categories, isLoading } = useGetCategoriesQuery({ gender: gender });

	const initialValues: TDemoFormValues = {
		gender: gender || INITIAL_GENDER,
		activeCategoryName: null,
		selectedClothes,
		nextAvailableCategory: null,
	};

	const selectedCounts = useMemo(() => {
		return createCategoryCounter(selectedClothes);
	}, [selectedClothes, gender]);

	useLockBodyScroll(isSheetOpen);

	return (
		// @todo uncomment when needed.
		// <TourWrapper steps={getCategoriesTourSteps(t, width > 1024)}>
		<Page
			description={t(
				'Try our interactive demo to create perfect outfit combinations. Select clothing items and get AI-powered recommendations. Start building your digital wardrobe!',
			)}
			image="https://www.lookify.app/assets/images/logo.png"
			title={t('Virtual Wardrobe Demo')}
		>
			<div className={styles.container}>
				<DemoPageStructuredData />
				<Formik<TDemoFormValues>
					initialValues={initialValues}
					key={gender}
					validationSchema={clothingSelectionSchema(t)}
					onSubmit={noop}
				>
					{(formProps) => {
						return (
							<FormPageLayout<TDemoFormValues>
								allowedNextPaths={[ROUTES.home(), ROUTES.myWardrobe()]}
								formProps={formProps}
								ignoreDirty={false}
								isSaveButtonHidden={true}
							>
								<div className={styles.wrapper}>
									{width > 768 && (
										<CategoryFilter
											categories={categories as ICategory[]}
											formProps={formProps}
											isLoading={isLoading}
											isSheetOpen={isSheetOpen}
											selectedCounts={selectedCounts}
											setIsSheetOpen={setIsSheetOpen}
										/>
									)}
									<ClothingSelector
										formProps={formProps}
										isSheetOpen={isSheetOpen}
										selectedCounts={selectedCounts}
										setIsSheetOpen={setIsSheetOpen}
									/>
									<SelectedClothesBottomSheet
										expandedBottomSheet={expandedBottomSheet}
										isSheetOpen={isSheetOpen}
										toggleBottomSheet={toggleBottomSheet}
									/>
								</div>
								<ClothingSelectorCta
									formProps={formProps}
									isBottomSheetExpanded={expandedBottomSheet}
									isCategoriesSheetOpen={isSheetOpen}
								/>
							</FormPageLayout>
						);
					}}
				</Formik>
			</div>
		</Page>
		// </TourWrapper>
	);
};

export type TDemoFormValues = {
	gender: TGender;
	activeCategoryName: IClothing['name'] | null;
	selectedClothes: IClothing[];
	nextAvailableCategory: IClothing['name'] | null;
};

export default ClothingSelectionPage;

import { useCallback } from 'react';
import type { IClothing } from '@typings';
import type { TDemoFormValues } from '../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';
import type { FormikProps } from 'formik';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import { useService } from '@services';

export const useClothingSelector = ({ formProps }: IUseClothingSelectorParams) => {
	const analytics = useService('AnalyticsService');
	const { setSelectedClothes } = useClothingActions();

	const { values, setFieldValue } = formProps;

	const onSelect = useCallback(
		(selectedCloth: IClothing) => {
			const isSelected = values.selectedClothes.some(({ name }) => name === selectedCloth.name);

			const updatedSelectedClothes: IClothing[] = isSelected
				? values.selectedClothes.filter(({ name }) => name !== selectedCloth.name)
				: [
						...values.selectedClothes,
						{
							...selectedCloth,
							category: values.activeCategoryName?.toLowerCase() || '',
						},
				  ];

			analytics.trackEvent('Clothing_Interaction', {
				isSelected: !isSelected,
				image_url: selectedCloth.image_url,
				category: selectedCloth.category,
				title: selectedCloth.name,
				gender: values.gender,
			});

			setFieldValue('selectedClothes', updatedSelectedClothes);
			setSelectedClothes(updatedSelectedClothes);
		},
		[values.selectedClothes, values.activeCategoryName, values.gender, setFieldValue, setSelectedClothes, analytics],
	);

	const onDiscard = useCallback(() => {
		setSelectedClothes([]);
		setFieldValue('selectedClothes', []);
	}, [setSelectedClothes, setFieldValue, values.selectedClothes, values.gender, analytics]);

	return { onSelect, onDiscard };
};

export interface IUseClothingSelectorParams {
	formProps: FormikProps<TDemoFormValues>;
}

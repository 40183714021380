import React from 'react';
import styles from './GroupedWardrobeSections.module.css';
import { ArrowLeftIcon, ArrowRightIcon, Badge, Button } from '@ui-kit';
import { useTranslation } from 'react-i18next';

const Header = ({
	title,
	selectedItemsCount,
	onScrollLeft,
	onScrollRight,
	canScrollLeft,
	canScrollRight,
	onReset,
}: IHeaderProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.myWardrobe__header}>
			<div className={styles.myWardrobe__headerLeft}>
				<h2 className={styles.myWardrobe__title}>{title.replace('_', ' ')}</h2>
				<Badge position={'inline'} value={selectedItemsCount} />
			</div>
			<div className={styles.myWardrobe__headerRight}>
				<Button title={t('Reset')} variant={'link'} onClick={onReset} />
				<div
					aria-disabled={!canScrollLeft}
					className={`${styles.myWardrobe__arrow} ${!canScrollLeft ? styles.myWardrobe__arrowDisabled : ''}`}
					role="button"
					tabIndex={canScrollLeft ? 0 : -1}
					onClick={canScrollLeft ? onScrollLeft : undefined}
					onKeyPress={canScrollLeft ? onScrollLeft : undefined}
				>
					<ArrowLeftIcon fill={!canScrollLeft ? 'gray' : 'var(--color-text-dark)'} height={20} width={20} />
				</div>
				<div
					aria-disabled={!canScrollRight}
					className={`${styles.myWardrobe__arrow} ${!canScrollRight ? styles.myWardrobe__arrowDisabled : ''}`}
					role="button"
					tabIndex={canScrollRight ? 0 : -1}
					onClick={canScrollRight ? onScrollRight : undefined}
					onKeyPress={canScrollRight ? onScrollRight : undefined}
				>
					<ArrowRightIcon fill={!canScrollRight ? 'gray' : 'var(--color-text-dark)'} height={20} width={20} />
				</div>
			</div>
		</div>
	);
};

export interface IHeaderProps {
	title: string;
	selectedItemsCount: number;
	onScrollLeft: () => void;
	onScrollRight: () => void;
	onReset: () => void;
	canScrollLeft: boolean;
	canScrollRight: boolean;
}

export default Header;

type TStructuredData = {
	'@context': string;
	'@type': string;
	name: string;
	url: string;
	image: string;
	description: string;
	screenshot?: string;
};

// Then create a component for the structured data
export const DemoPageStructuredData = () => {
	const structuredData: TStructuredData = {
		'@context': 'https://schema.org',
		'@type': 'WebPage',
		name: 'Virtual Wardrobe Demo',
		url: typeof window !== 'undefined' ? window.location.href : '',
		image: 'https://www.lookify.app/assets/images/logo.png',
		description: 'Interactive clothing selection interface for building AI-powered outfits',
		screenshot: 'https://www.lookify.app/assets/images/demo-screenshot.png',
	};

	if (!structuredData.image || !structuredData.description) {
		console.error('Structured data validation failed: Missing required fields');
		return null;
	}

	return <script type="application/ld+json">{JSON.stringify(structuredData, null, 2)}</script>;
};

import * as yup from 'yup';
import { MIN_SELECTION_COUNT, MIN_SELECTION_ALL_BODY_COUNT, MANDATORY_FIELDS_DEFAULT } from '@constants';
import type { TFunction } from 'i18next';

// Schema for an individual clothing item
const clothingItemSchema = yup.object().shape({
	name: yup.string().required(),
	image_url: yup.string().required(),
	category: yup.string().required(),
});

// Helper: Validate that each field in mandatoryFields meets the minimum count.
const validateMandatoryFields = (counts: { [x: string]: number }, mandatoryFields: string[], t: TFunction) => {
	for (const field of mandatoryFields) {
		const minCount = field === 'all_body' ? MIN_SELECTION_ALL_BODY_COUNT : MIN_SELECTION_COUNT;
		if ((counts[field] || 0) < minCount) {
			return t(`Select at least {{minSelectionCount}} {{field}}.`, {
				field: field.replace('_', ' '),
				minSelectionCount: minCount,
			});
		}
	}
	return null;
};

export const clothingSelectionSchema = (t: TFunction) =>
	yup.object().shape({
		gender: yup.string().oneOf(['masculine', 'feminine']).required(),
		selectedClothes: yup
			.array()
			.of(clothingItemSchema)
			// eslint-disable-next-line sonarjs/cognitive-complexity
			.test('mandatory-check', t(`Missing mandatory clothing items.`), function (selectedClothes = []) {
				const { gender } = this.parent;
				const counts: { [key: string]: number } = {};

				// Build category counts
				selectedClothes.forEach((item) => {
					const cat = item.category?.toLowerCase() as string;
					counts[cat] = (counts[cat] || 0) + 1;
				});

				if (gender === 'feminine') {
					const hasAllBody = (counts['all_body'] || 0) > 0;
					const hasTopsOrBottoms = (counts['tops'] || 0) > 0 || (counts['bottoms'] || 0) > 0;

					// 1. All-body path validation
					if (hasAllBody) {
						const allBodyCount = counts['all_body'] || 0;
						const shoesCount = counts['shoes'] || 0;

						if (allBodyCount < MIN_SELECTION_ALL_BODY_COUNT) {
							return this.createError({
								message: t(`Select at least {{min}} all body outfits`, {
									min: MIN_SELECTION_ALL_BODY_COUNT,
								}),
							});
						}

						if (shoesCount < MIN_SELECTION_COUNT) {
							return this.createError({
								message: t(`Select at least {{min}} shoes`, {
									min: MIN_SELECTION_COUNT,
								}),
							});
						}

						return true;
					}

					// 2. Tops/bottoms path validation
					if (hasTopsOrBottoms) {
						const topsCount = counts['tops'] || 0;
						const bottomsCount = counts['bottoms'] || 0;
						const shoesCount = counts['shoes'] || 0;

						if (topsCount < MIN_SELECTION_COUNT) {
							return this.createError({
								message: t(`Select at least {{min}} tops`, {
									min: MIN_SELECTION_COUNT,
								}),
							});
						}

						if (bottomsCount < MIN_SELECTION_COUNT) {
							return this.createError({
								message: t(`Select at least {{min}} bottoms`, {
									min: MIN_SELECTION_COUNT,
								}),
							});
						}

						if (shoesCount < MIN_SELECTION_COUNT) {
							return this.createError({
								message: t(`Select at least {{min}} shoes`, {
									min: MIN_SELECTION_COUNT,
								}),
							});
						}

						return true;
					}

					// 3. No selections made a - show general message
					return this.createError({
						message: t(`Choose {{min}} tops, bottoms & shoes, or all body outfits with shoes`, {
							min: MIN_SELECTION_COUNT,
						}),
					});
				}

				// Masculine validation remains unchanged
				const errorMessage = validateMandatoryFields(counts, MANDATORY_FIELDS_DEFAULT, t);
				if (errorMessage) return this.createError({ message: errorMessage });
				return true;
			}),
	});

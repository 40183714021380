import React, { useState } from 'react';
import { FullScreenPageLayout, Page } from '../../components';
import { useNavigate, useNotification, useScreenSize, useService, useTranslation } from '@hooks';
import styles from './WardrobePage.module.css';
import GroupedWardrobeSections from '../../../ui-modules/bestOutfit/components/GroupedWardrobeSections';
import { Badge, Button, ExclamationStrokeIcon, MagicIcon, Modal, StarsIcon } from '@ui-kit';
import { ROUTES } from '@constants';
import { getSelectedItems } from '@ui-modules/clothing/utils/getSelectedItems';
import { useGetBestOutfitsMutation } from '@ui-modules/bestOutfit/hooks/useGetBestOutfitsMutation';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';
import { removeImageFormat, debounce, downloadImages } from '@utils';
import { useLocation } from 'react-router-dom';
import { useGetBestOutfitsConvexQuery } from '@ui-modules/bestOutfit/hooks/useGetBestOutfitsConvexQuery';
import { useDeleteBestOutfitConvexMutation } from '@ui-modules/bestOutfit/hooks/useDeleteBestOutfitConvexMutation';
import QuestionTooltip from '../../../ui-kit/components/QuestionTooltip';
import CursorContent from './CursorContent';
import clsx from 'clsx';
import { useWardrobeValidation } from '@ui-modules/clothing/hooks/useWardrobeValidation';

const WardrobePage = () => {
	const { t } = useTranslation();
	const route = useLocation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const { wardrobeItems, selectedClothes, gender } = useClothingActions();

	const { isValid } = useWardrobeValidation();

	const { showWarning, showInfo } = useNotification();
	const { mutateAsync: deleteBestOutfit } = useDeleteBestOutfitConvexMutation();
	const { data: bestOutfits, isFetched, refetch } = useGetBestOutfitsConvexQuery();
	const { mutateAsync, isLoading } = useGetBestOutfitsMutation({
		onSuccess: (data) => {
			if (data?.bestOutfitUrls?.length === 0) {
				showWarning({ title: t('No matches found. Please select more outfits and try again!') });
				return;
			}

			if (route.pathname !== ROUTES.bestOutfits() && isFetched) {
				analytics.trackEvent('Best_Outfits', {
					feature_name: 'generate best outfits',
					applied_outfits: selectedClothes,
					received_outfits: data.bestOutfitUrls,
					gender: gender,
				});

				const debouncedCallback = debounce(async () => {
					await refetch();
					navigate(ROUTES.bestOutfits());
				}, 1000);

				debouncedCallback();
			}
		},
	});

	const selectedItems = getSelectedItems(wardrobeItems);
	const images = selectedItems.map((item) => removeImageFormat(item.name));

	const [showDiscardModal, setShowDiscardModal] = useState(false);

	const handleGenerateNewOutfits = async () => {
		showInfo({
			title: t('Organizing your outfits...'),
			subtitle: t('AI engine is working on your outfits! This may take a few minutes.'),
			duration: 10000,
		});

		await mutateAsync({ imageUrls: images });
	};

	// When the user clicks "Generate Results"
	const handleGenerateClick = () => {
		if (bestOutfits && bestOutfits.length > 0) {
			// If we already have best outfits, ask for confirmation
			setShowDiscardModal(true);
		} else {
			// Otherwise, just generate new
			handleGenerateNewOutfits();
		}
	};

	// Placeholder for "download" logic
	const handleDownloadOldOutfits = async () => {
		if (bestOutfits && bestOutfits?.length > 0) {
			const outfitUrls = bestOutfits?.map((outfit) => outfit.outfit_url);
			await downloadImages(outfitUrls);
			analytics.trackEvent('Download_Best_Outfits', {
				outfit_urls: outfitUrls,
				gender: gender,
			});
		}
	};

	const { width } = useScreenSize();

	return (
		<Page title={t('My Wardrobe')}>
			<div className={styles.wardrobe__contentWrapper}>
				<FullScreenPageLayout
					backButtonEnabled
					headerTitle={t('My Wardrobe')}
					isFixed={false}
					rightButton={
						<div className={styles.wardrobe__rightCTAWrapper}>
							<Button
								block={width <= 400 ? 'fit' : 'inline'}
								className={styles.wardrobe__cta}
								disabled={!isValid}
								icon={
									<MagicIcon
										className={clsx(styles.wardrobe__magicIcon, !isValid && styles.wardrobe__magicIcon_disabled)}
									/>
								}
								iconPosition="right"
								loading={isLoading}
								size="small"
								title={t('Generate')}
								type="submit"
								variant="clear"
								onClick={handleGenerateClick}
							/>
							<QuestionTooltip
								className={styles.wardrobe__exclamationTooltip}
								content={<CursorContent selectedItems={selectedItems} />}
								Icon={ExclamationStrokeIcon}
								place="bottom-start"
							/>
						</div>
					}
				>
					<div className={styles.wardrobe__contentWrapper}>
						<div className={styles.wardrobe__container}>
							<div className={styles.wardrobe__bestOutfitWrapper}>
								{/* Link to Best Outfits page */}
								<Button
									badge={
										<Badge
											hidden={!bestOutfits?.length}
											position={width > 1250 ? 'inline' : 'absolute'}
											value={bestOutfits?.length}
										/>
									}
									block={width <= 400 ? 'fit' : 'inline'}
									className={styles.wardrobe__bestOutfitClickable}
									disabled={bestOutfits?.length === 0}
									icon={<StarsIcon className={styles.wardrobe__starsIcon} />}
									iconPosition="right"
									title={t('Best Outfits')}
									variant={width > 1250 ? 'link' : 'tertiary'}
									onClick={() => navigate(ROUTES.bestOutfits())}
								/>
							</div>
							<GroupedWardrobeSections />
						</div>
					</div>
				</FullScreenPageLayout>
			</div>

			<Modal
				cancelTitle={t('Yes, remove old outfits')}
				confirmTitle={t('Cancel')}
				extraFooter={<Button title={t('Download best outfits')} variant="clear" onClick={handleDownloadOldOutfits} />}
				subTitle={t(
					'You already have generated best outfits. Generating new ones will remove the old results. Do you want to proceed?',
				)}
				title={t('Generate new outfits?')}
				variant="medium"
				visible={showDiscardModal}
				onCancel={async () => {
					setShowDiscardModal(false);
					bestOutfits?.map(async (outfit) => {
						await deleteBestOutfit({ id: outfit.id });
					});
					await handleGenerateNewOutfits();
				}}
				onConfirm={() => {
					setShowDiscardModal(false);
				}}
			/>
		</Page>
	);
};

export default WardrobePage;

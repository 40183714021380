import { useScreenSize, useTranslation } from '@hooks';
import styles from './Hero.module.css';
import { Button, CardSwiper, MagicIcon } from '@ui-kit';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import Image from 'rc-image';
import { demoButtonVariants, heroBlockSwipeImages } from '@ui-modules/home/components/Hero/utils';
import React, { useEffect, useRef, useState } from 'react';

import { useInView } from 'framer-motion';
import { motion } from 'framer-motion';

const Hero = () => {
	const { t } = useTranslation();
	const screenSize = useScreenSize();
	const navigate = useNavigate();

	const isTablet = screenSize.width <= 1024;
	const buttonRef = useRef<HTMLDivElement>(null);

	const buttonInView = useInView(buttonRef, {
		margin: '0px 0px -50px 0px',
		once: true,
		amount: 'some',
	});

	const onGetEarlyAccessClick = () => {
		const targetElement = document.querySelector('#join-us');
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
		navigate(ROUTES.joinUs());
	};

	const [mounted, setMounted] = useState(false);

	let timeout: NodeJS.Timeout;
	useEffect(() => {
		const handleLoad = () => setMounted(true);

		if (document.readyState === 'complete') {
			timeout = setTimeout(() => setMounted(true), 200);
		} else {
			window.addEventListener('load', handleLoad);
			return () => {
				clearTimeout(timeout);
				window.removeEventListener('load', handleLoad);
			};
		}
	}, []);

	const onDemoClick = () => {
		navigate(ROUTES.clothingSelection());
	};

	const cardSwiper = (
		<div className={styles.hero__image}>
			<CardSwiper cards={heroBlockSwipeImages} />
		</div>
	);

	return (
		<section className={styles.hero}>
			<div className={styles.hero__content}>
				<h1 className={styles.hero__title}>
					{t('Your AI-powered ')} <span className={styles['hero__title_highlight']}>{t('wardrobe assistant')} </span>
					{t('your best looks every day')}
					<span className={styles['hero__subtitle_highlight']}> {t('find, match, and shine')}</span>
				</h1>
				{screenSize.width <= 1024 && cardSwiper}
				<p className={styles.hero__description}>
					{t(
						'Simplify your style routine with AI-powered outfit recommendations. Organize your wardrobe, pair new purchases with confidence, and enjoy the satisfaction of perfectly matched looks for any occasion.',
					)}
				</p>
				{screenSize.width > 1024 && (
					<div className={styles.hero__actions}>
						<motion.div
							animate={mounted ? 'animate' : 'initial'}
							initial="initial"
							style={{
								background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
								borderRadius: '12px',
								display: 'inline-block',
							}}
							variants={demoButtonVariants}
							whileHover="hover"
							whileTap="tap"
						>
							<Button
								block={'inline'}
								icon={<MagicIcon fill={'#141B24'} />}
								size="large"
								title={t('Try Demo Now')}
								onClick={onDemoClick}
							/>
						</motion.div>
						<Button
							block={'inline'}
							size="large"
							title={t('Join Now!')}
							variant={'secondary'}
							onClick={onGetEarlyAccessClick}
						/>
					</div>
				)}
			</div>
			{screenSize.width > 1024 && (
				<div className={styles.hero__image}>
					<Image
						alt="Hero Grid"
						className={styles.hero__image}
						placeholder
						preview={false}
						src={'/assets/images/hero_grid.webp'}
					/>
				</div>
			)}
			{screenSize.width <= 1024 && (
				<div className={styles.hero__actions}>
					{isTablet && (
						<div className={styles.hero__demoCTAWrapper} ref={buttonRef}>
							<motion.div
								animate={buttonInView ? 'animate' : 'initial'}
								initial="initial"
								style={{
									background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
									borderRadius: '12px',
									display: 'inline-block',
									width: '100%',
								}}
								variants={demoButtonVariants}
								whileHover="hover"
								whileTap="tap"
							>
								<Button
									block={'fit'}
									icon={<MagicIcon fill={'#141B24'} />}
									size="large"
									title={t('Try Demo Now')}
									onClick={onDemoClick}
								/>
							</motion.div>
						</div>
					)}
					<Button
						block={'fit'}
						size="large"
						title={t('Join Now!')}
						variant={'secondary'}
						onClick={onGetEarlyAccessClick}
					/>
				</div>
			)}
		</section>
	);
};

export default Hero;

import type { IClothingState } from '../../../services/implementations/ReduxService/slices/clothingSlice.slice';
import type { IClothing } from '@typings';

export const getSelectedItems = (data: IClothingState['wardrobeItems']): IClothing[] => {
	const selectedItems: IClothing[] = [];

	for (const category in data) {
		// eslint-disable-next-line no-prototype-builtins
		if (data?.hasOwnProperty?.(category)) {
			const items = data[category];
			const selected = items.filter((item) => item.isSelected);
			selectedItems.push(...selected);
		}
	}

	return selectedItems;
};

import { useTranslation } from '@hooks';
import styles from './ReviewForm.module.css';
import type { FormikProps } from 'formik';
import { Button, CheckboxGroupInput, RadioGroupInput } from '@ui-kit';
import TextArea from '@ui-kit/components/TextArea';
import { useEffect } from 'react';

const ReviewForm = ({ formProps, isSuccess, isLoading }: IReviewFormProps) => {
	const { t } = useTranslation();

	const { values, setFieldValue, errors, isValid, handleSubmit, resetForm } = formProps;
	const PURPOSE_OPTIONS = [
		{ value: 'shopping', label: t("Purchase items I don't have") },
		{ value: 'save', label: t('Save for later inspiration') },
		{ value: 'share', label: t('Share with friends/style community') },
		{ value: 'travel', label: t('Plan travel outfits') },
	];

	const STYLE_OPTIONS = [
		{ value: 'casual', label: t('Casual everyday looks') },
		{ value: 'professional', label: t('Work-appropriate styles') },
		{ value: 'trendy', label: t('Latest fashion trends') },
		{ value: 'sustainable', label: t('Sustainable fashion choices') },
	];

	const COMPATIBILITY_OPTIONS = [
		{ value: 'very_compatible', label: t('Very Compatible') },
		{ value: 'somewhat_compatible', label: t('Somewhat Compatible') },
		{ value: 'neutral', label: t('Neutral') },
		{ value: 'not_very_compatible', label: t('Not Very Compatible') },
		{ value: 'not_compatible', label: t('Not Compatible') },
	];

	const INTUITIVENESS_OPTIONS = [
		{ value: 'very_intuitive', label: t('Very Intuitive') },
		{ value: 'somewhat_intuitive', label: t('Somewhat Intuitive') },
		{ value: 'neutral', label: t('Neutral') },
		{ value: 'not_very_intuitive', label: t('Not Very Intuitive') },
		{ value: 'not_intuitive', label: t('Not Intuitive') },
	];

	useEffect(() => {
		if (isSuccess) {
			resetForm();
		}
	}, [isSuccess]);

	const handleCheckboxChange = (field: keyof TReviewForm, value: string) => {
		const currentValues = values[field] as string[];
		const newValues = currentValues.includes(value)
			? currentValues.filter((v) => v !== value)
			: [...currentValues, value];

		setFieldValue(field, newValues);
	};

	return (
		<form className={styles.reviewForm__container} onSubmit={handleSubmit}>
			<div className={styles.reviewForm__titleWrapper}>
				<h3 className={styles.reviewForm__title}>
					<span className={styles.reviewForm__how}>{t('How')}</span> {t('did we do? Share your style')}{' '}
					<span className={styles.reviewForm__experience}>{t('experience')}</span>
				</h3>
				<h4>
					{t(
						'We’d love to hear your thoughts! Let us know how these looks fit your style and help us improve your experience.',
					)}
				</h4>
			</div>

			<div className={styles.reviewForm__sectionsWrapper}>
				<CheckboxGroupInput
					errorMessage={errors.usagePurpose as string}
					label={t('How would you use these outfits?')}
					labelType={'bold'}
					options={PURPOSE_OPTIONS}
					value={values.usagePurpose}
					onChange={(value) => handleCheckboxChange('usagePurpose', value)}
				/>
				<CheckboxGroupInput
					errorMessage={errors.stylePreferences as string}
					label={t('What style aspects matter most to you?')}
					labelType={'bold'}
					options={STYLE_OPTIONS}
					value={values.stylePreferences}
					onChange={(value) => handleCheckboxChange('stylePreferences', value)}
				/>
				<RadioGroupInput
					backgroundColor={'white'}
					errorMessage={errors.compatibility}
					label={t('How compatible were the items in the outfits?')}
					labelType={'bold'}
					options={COMPATIBILITY_OPTIONS}
					value={values.compatibility}
					onChange={(value) => {
						setFieldValue('compatibility', value);
					}}
				/>
				<RadioGroupInput
					backgroundColor={'white'}
					errorMessage={errors.intuitiveness}
					label={t('How intuitive was the interface for outfit generation?')}
					labelType={'bold'}
					options={INTUITIVENESS_OPTIONS}
					value={values.intuitiveness}
					onChange={(value) => {
						setFieldValue('intuitiveness', value);
					}}
				/>
			</div>

			<div className={styles.reviewForm__reviewWrapper}>
				<TextArea
					errorMessage={errors.review}
					errorMessagePosition={'right'}
					maxRows={8}
					minRows={8}
					placeholder={t('Enter your thoughts...')}
					rounded
					value={values.review}
					onChange={(e) => setFieldValue('review', e.target.value)}
				/>
				<Button
					block={'fit'}
					disabled={!isValid}
					loading={isLoading}
					title={t('Submit')}
					type="submit"
					variant="secondary"
				/>
			</div>
		</form>
	);
};

export interface IReviewFormProps {
	formProps: FormikProps<TReviewForm>;
	isSuccess: boolean;
	isLoading: boolean;
}

export type TReviewForm = {
	review: string;
	usagePurpose: string[];
	stylePreferences: string[];
	compatibility: string;
	intuitiveness: string;
};

export default ReviewForm;

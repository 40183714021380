import { gridComponents } from '@ui-modules/clothing/components/ClothingSelector/utils';
import ClothingItemCard from '@ui-modules/clothing/components/ClothingItemCard';
import { VirtuosoGrid } from 'react-virtuoso';
import React from 'react';
import type { IClothing } from '@typings';
import styles from './ClothingSelector.module.css';
import { ActivityIndicator } from '@ui-kit';

export const DesktopGrid = ({ clothes, selectedClothes, onSelect, isLoading }: IDesktopGridProps) => {
	return isLoading ? (
		<div className={styles.clothingSelector__activityIndicatorWrapper}>
			<ActivityIndicator />
		</div>
	) : (
		<VirtuosoGrid
			components={gridComponents}
			computeItemKey={(index, item) => `${item.name}_${index}`}
			data={clothes || []}
			initialItemCount={clothes?.length || 0}
			itemContent={(index, item) => (
				<ClothingItemCard
					isSelected={selectedClothes.some((cloth) => cloth.name === item.name)}
					item={item}
					onSelect={onSelect}
				/>
			)}
			style={{ height: '80vh', width: '100%' }}
			totalCount={clothes?.length || 0}
		/>
	);
};

export interface IDesktopGridProps {
	clothes: IClothing[];
	selectedClothes: IClothing[];
	onSelect: (cloth: IClothing) => void;
	isLoading: boolean;
}

import React, { useLayoutEffect, useState } from 'react';
import { useScreenSize } from '@hooks';
import { Button, CloseIcon, HamburgerIcon, HeaderDemoTooltip, MagicIcon } from '@ui-kit';

import { useMenu } from './hooks/TabBar.useMenu';
import { useNavigation } from './hooks/TabBar.useNavigation';

import Logo from './components/Logo';
import SmallTabBar from './components/SmallTabBar';
import LargeTabBar from './components/LargeTabBar';

import styles from './TabBar.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useTranslation } from 'react-i18next';

const TabBar = () => {
	const { width } = useScreenSize();
	const { activeNav, handleNavigation } = useNavigation();
	const { menuOpen, setMenuOpen, toggleMenu } = useMenu();

	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tooltipReady, setTooltipReady] = useState<boolean>(false);

	useLayoutEffect(() => {
		setTooltipReady(true);
	}, []);

	const onDemoClick = () => {
		navigate(ROUTES.clothingSelection());
	};

	return (
		<header className={styles.header}>
			<div className={styles.header__container} id={styles['header']}>
				<div className={styles.header__wrapper}>
					<Logo />
					{location.pathname === ROUTES.home() && (
						<>
							{width > 1024 ? (
								<LargeTabBar activeNav={activeNav} handleNavigation={handleNavigation} />
							) : (
								<div className={styles.header__menuWrapper}>
									<>
										{tooltipReady && (
											<HeaderDemoTooltip
												description={t(
													'Let our AI stylist reveal 3 stunning looks in your free session. Style smarter, not harder!',
												)}
												setShowTooltip={setTooltipReady}
												showTooltip={true}
												title={t('Your Free Style Session Awaits!')}
											/>
										)}
										<div data-tooltip-id="demo-tooltip">
											<Button
												block={'inline'}
												className={styles.header__demoCTA_mobile}
												icon={<MagicIcon fill={'#141B24'} />}
												size="extra_small"
												title={width < 370 ? '' : t('Demo')}
												onClick={onDemoClick}
											/>
										</div>
									</>
									<Button
										icon={menuOpen ? <CloseIcon height={20} width={20} /> : <HamburgerIcon height={20} width={20} />}
										size="extra_small"
										variant="clear"
										onClick={toggleMenu}
									/>
								</div>
							)}
						</>
					)}
				</div>

				{width > 1024 && location.pathname === ROUTES.home() && (
					<>
						{tooltipReady && (
							<HeaderDemoTooltip
								description={t(
									'Let our AI stylist reveal 3 stunning looks in your free session. Style smarter, not harder!',
								)}
								setShowTooltip={setTooltipReady}
								showTooltip={true}
								title={t('Your Free Style Session Awaits!')}
							/>
						)}
						<div data-tooltip-id="demo-tooltip">
							<Button
								block={'inline'}
								icon={<MagicIcon fill={'#141B24'} />}
								size="extra_small"
								title={t('Demo')}
								onClick={onDemoClick}
							/>
						</div>
					</>
				)}
				{menuOpen && width <= 1024 && (
					<SmallTabBar
						activeNav={activeNav}
						handleNavigation={handleNavigation}
						menuOpen={menuOpen}
						setMenuOpen={setMenuOpen}
					/>
				)}
			</div>
		</header>
	);
};

export default TabBar;

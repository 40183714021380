import type { Variants } from 'framer-motion';

export const heroBlockSwipeImages = [
	'/assets/images/hero_cards/hero_1.jpg',
	'/assets/images/hero_cards/hero_2.png',
	'/assets/images/hero_cards/hero_3.png',
	'/assets/images/hero_cards/hero_4.png',
	'/assets/images/hero_cards/hero_5.png',
	'/assets/images/hero_cards/hero_6.png',
	'/assets/images/hero_cards/hero_7.png',
	'/assets/images/hero_cards/hero_8.png',
	'/assets/images/hero_cards/hero_9.png',
];

export const demoButtonVariants: Variants = {
	initial: {
		x: -1000,
	},
	animate: {
		x: 0,
		opacity: 1,
		rotate: 0,
		scale: 1,
		transition: {
			type: 'spring',
			restSpeed: 200,
			stiffness: 200,
			damping: 20,
			mass: 2,
		},
	},
	hover: {
		y: -8,
		scale: 1.05,
		rotate: 2,
		transition: {
			duration: 0.3,
			ease: 'easeInOut',
		},
	},
	tap: {
		scale: 0.95,
		rotate: -1,
	},
};

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { FormikProps } from 'formik';

import { useTranslation, useScreenSize, useEvent } from '@hooks';
import { AnimatedText, ArrowRightIcon, CloseIcon, LoadingSkeleton } from '@ui-kit';
import GenderSelector from '@ui-modules/clothing/components/GenderSelector';
import CategoryList from '../CategoryList';
import { useTour } from '@reactour/tour';
import { useWalkthroughShown } from '@ui-modules/clothing/hooks/useWalkthroughShown';
import { useClothingActions } from '@ui-modules/clothing/hooks/useClothingActions';

import styles from './CategoryFilter.module.css';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';
import type { ICategory, TGender } from '@typings';
import clsx from 'clsx';

import { BottomSheet } from 'react-spring-bottom-sheet';
import './CategoryFilter.overwrite.css';
import { HEADER_HEIGHT } from '@constants';

const CategoryFilter: React.FC<ICategoryFilterProps> = ({
	formProps,
	isSheetOpen,
	setIsSheetOpen,
	selectedCounts,
	categories,
	isLoading,
}) => {
	const ref = useRef(null);
	const { t } = useTranslation();
	const { width, height } = useScreenSize();
	const { setFieldValue, values } = formProps;

	const { resetState } = useClothingActions();
	const selectedCategory = useMemo(
		() => categories?.find((item) => item.name === values.activeCategoryName),
		[values.activeCategoryName, categories],
	);

	const onGenderSelect = useEvent((gender: TGender) => {
		setFieldValue('gender', gender);
		categories && setFieldValue('activeCategoryName', null);
		resetState(gender);
	});

	useEffect(() => {
		if (values.activeCategoryName) {
			const currentCategoryIndex = categories?.findIndex((item) => item.name === values.activeCategoryName) || 0;
			const nextCategory = categories?.[currentCategoryIndex + 1];
			setFieldValue('nextAvailableCategory', nextCategory?.name || categories?.[0].name);
		}
	}, [values.activeCategoryName]);

	useEffect(() => {
		if (categories?.length && !values.activeCategoryName) {
			setFieldValue('activeCategoryName', categories[0].name);
		}
	}, [categories, values.activeCategoryName]);

	const handleCategorySelect = useCallback(
		(categoryName: string) => {
			setFieldValue('activeCategoryName', categoryName);
			if (width <= 768) setIsSheetOpen(false);
		},
		[setFieldValue, width, setIsSheetOpen],
	);

	const { setIsOpen, currentStep, steps } = useTour();
	const { getWalkthroughShownCategories, setCategoriesWalkthroughShown } = useWalkthroughShown();

	// Trigger walkthrough if needed
	useEffect(() => {
		(async () => {
			const walkthroughShown = await getWalkthroughShownCategories();
			if (!walkthroughShown) setIsOpen(true);
		})();
	}, [getWalkthroughShownCategories, setIsOpen]);

	useEffect(() => {
		if (currentStep + 1 === steps?.length) {
			setCategoriesWalkthroughShown();
		}
	}, [currentStep, steps, setCategoriesWalkthroughShown]);

	// Mobile sheet toggling based on tour steps
	useLayoutEffect(() => {
		if (width > 768) return;
		if (currentStep === 1 || currentStep === 2) {
			setIsSheetOpen(true);
		} else {
			setIsSheetOpen(false);
		}
	}, [currentStep, width, setIsSheetOpen]);

	const animatedHint = useMemo(
		() => (formProps.errors.selectedClothes as string) || t('Click "My Wardrobe" now to reveal your collection!'),
		[formProps.errors.selectedClothes, formProps?.values?.selectedClothes],
	);

	if (width > 768) {
		return (
			<div className={styles.container}>
				<h4 className={styles.title}>{t('Categories')}</h4>
				<GenderSelector
					formProps={formProps}
					gender={values.gender}
					isSheetOpen={isSheetOpen}
					setGender={onGenderSelect}
					setIsSheetOpen={setIsSheetOpen}
				/>
				<AnimatedText className={styles.categoryFilter__errorMessage} key={animatedHint} text={animatedHint} />
				<CategoryList
					activeCategoryName={values.activeCategoryName}
					categories={categories}
					isLoading={isLoading}
					selectedCounts={selectedCounts}
					onCategorySelect={handleCategorySelect}
				/>
			</div>
		);
	}

	// Mobile view
	return (
		<div
			className={clsx(
				styles.container_mobile,
				values.gender === 'feminine' ? styles.container_mobileFeminine : styles.container_mobileMasculine,
			)}
		>
			<div className={styles.container}>
				<div
					className={styles.categoryFilter__metaContainer}
					role="button"
					tabIndex={0}
					onClick={() => setIsSheetOpen(true)}
					onKeyPress={() => setIsSheetOpen(true)}
				>
					<div>
						<h4 className={styles.title}>{t('Categories')}</h4>
						<div className={styles.categoryFilter__metaWrapper}>
							<p>{values.gender}</p>
							{isLoading ? <LoadingSkeleton height={16} width={100} /> : <p>{selectedCategory?.title}</p>}
						</div>
					</div>
					<ArrowRightIcon fill="var(--color-secondary-gray-700)" height={20} width={20} />
				</div>
				<BottomSheet open={isSheetOpen} ref={ref} snapPoints={() => [height - HEADER_HEIGHT - 16]}>
					<div className={styles.categoryFilter__categoriesContainer}>
						<div
							className={styles.categoryFilter__header}
							onClick={(e) => e.stopPropagation()}
							onKeyPress={(e) => e.stopPropagation()}
						>
							<h4 className={styles.categoryFilter__metaContainerTitle}>{t('Categories')}</h4>
							<div
								className={styles.categoryFilter__headerButton}
								onClick={() => setIsSheetOpen(false)}
								onKeyPress={() => setIsSheetOpen(false)}
							>
								<CloseIcon height={20} width={20} />
							</div>
						</div>
						<GenderSelector
							formProps={formProps}
							gender={values.gender}
							isSheetOpen={isSheetOpen}
							setGender={onGenderSelect}
							setIsSheetOpen={setIsSheetOpen}
						/>
						<div className={styles.categoryFilter__categoriesWrapper}>
							<CategoryList
								activeCategoryName={values.activeCategoryName}
								categories={categories}
								isLoading={isLoading}
								selectedCounts={selectedCounts}
								onCategorySelect={handleCategorySelect}
							/>
						</div>
					</div>
				</BottomSheet>
				<AnimatedText className={styles.categoryFilter__errorMessage} key={animatedHint} text={animatedHint} />
			</div>
		</div>
	);
};

export interface ICategoryFilterProps {
	formProps: FormikProps<TDemoFormValues>;
	isSheetOpen: boolean;
	selectedCounts: Record<string, number>;
	setIsSheetOpen: Dispatch<SetStateAction<boolean>>;
	categories: ICategory[];
	isLoading?: boolean;
}

export default CategoryFilter;

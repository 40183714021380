import { useQuery, useService } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { ICategory, TGender } from '@typings';
import { CATEGORY_ORDER } from '@constants';

export const useGetCategoriesQuery = <T = TUseGetCategoriesQueryData>(
	params: TUseGetCategoriesQueryParams,
	queryOptions?: UseQueryOptions<TUseGetCategoriesQueryData, Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TUseGetCategoriesQueryData, Error, T>(
		['getCategories', params.gender],
		async () => {
			const categories = await api.clothing.getCategories(params);

			const orderedCategories = categories
				? [...categories].sort((a, b) => {
						const indexA = CATEGORY_ORDER.indexOf(a.name);
						const indexB = CATEGORY_ORDER.indexOf(b.name);
						if (indexA === -1 && indexB === -1) return a.title.localeCompare(b.title);
						if (indexA === -1) return 1;
						if (indexB === -1) return -1;
						return indexA - indexB;
				  })
				: [];
			return orderedCategories;
		},
		{
			cacheTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			staleTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};
type TUseGetCategoriesQueryParams = { gender: TGender };
// @todo change this one to category
type TUseGetCategoriesQueryData = ICategory[];

import { useService } from '@hooks';
import { sortClothingData } from '@ui-modules/bestOutfit/utils/sortWardrobeItems';

export const useClothingActions = () => {
	const { clothingSlice, useBindActionCreators, useAppSelector } = useService('ReduxService');
	const clothingActions = useBindActionCreators(clothingSlice);
	const selectedClothes = useAppSelector((state) => state.clothingSlice.selectedClothes);
	const uploadedClothes = useAppSelector((state) => state.clothingSlice.uploadedClothes);
	const wardrobeItems = useAppSelector((state) => state.clothingSlice.wardrobeItems);
	const gender = useAppSelector((state) => state.clothingSlice.gender);
	const bestOutfits = useAppSelector((state) => state.clothingSlice.bestOutfits);
	const isCompressing = useAppSelector((state) => state.clothingSlice.isCompressing);
	const canProceedToWardrobe = useAppSelector((state) => state.clothingSlice.canProceedToWardrobe);

	return {
		// selectors
		selectedClothes,
		uploadedClothes,
		wardrobeItems: sortClothingData(wardrobeItems),
		gender,
		bestOutfits,
		isCompressing,
		canProceedToWardrobe,
		// actions
		setUploadedClothes: clothingActions.setUploadedClothes,
		setSelectedClothes: clothingActions.setSelectedClothes,
		removeUploadedImage: clothingActions.removeUploadedCloth,
		setWardrobeItems: clothingActions.setWardrobeItems,
		setGender: clothingActions.setGender,
		toggleWardrobeItemSelection: clothingActions.toggleWardrobeItemSelection,
		resetWardrobeItemsSelection: clothingActions.resetWardrobeItemsSelection,
		setBestOutfits: clothingActions.setBestOutfits,
		removeBestOutfit: clothingActions.removeBestOutfit,
		setIsCompressing: clothingActions.setIsCompressing,
		setCanProceedToWardrobe: clothingActions.setCanProceedToWardrobe,
		resetState: clothingActions.resetState,
	};
};

import styles from './BestOutfit.module.css';
import StarsRating from '../StarsRating';
import React, { useState } from 'react';
import Image from 'rc-image';
import { useRateBestOutfitMutation } from '@ui-modules/bestOutfit/hooks/useRateBestOutfitMutation';
import type { IBestOutfit } from '@typings';

const BestOutfit = ({ outfit_url, rating: storedRating, id }: IBestOutfitProps) => {
	const [rating, setRating] = useState(storedRating || 0);
	const { mutateAsync: rateOutfit } = useRateBestOutfitMutation();

	return (
		<div className={styles.bestOutfit__container}>
			<Image
				alt="Best Outfit"
				className={styles.bestOutfitContent__bestImage}
				preview={false}
				src={outfit_url}
				onError={() => console.error(`Failed to load image: ${outfit_url}`)}
			/>
			<div className={styles.bestOutfit__ratingWrapper}>
				<StarsRating
					rating={rating || 0}
					onClick={async (rating) => {
						await rateOutfit({ id, rating });
						setRating(rating);
					}}
				/>
			</div>
		</div>
	);
};

export interface IBestOutfitProps extends IBestOutfit {}

export default BestOutfit;

import React, { useEffect } from 'react';

import ClothingItemCard from '@ui-modules/clothing/components/ClothingItemCard';

import styles from './ClothingSelector.module.css';

import type { FormikProps } from 'formik';
import { ActivityIndicator } from '@ui-kit';
import type { ICategory, IClothing } from '@typings';

import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';
import { HEADER_HEIGHT } from '@constants';
import CategoryFilter from '@ui-modules/clothing/components/CategoryFilter';

export const MobileList = ({
	clothes,
	selectedClothes,
	onSelect,
	isSheetOpen,
	setIsSheetOpen,
	selectedCounts,
	formProps,
	categories,
	isLoading,
}: IMobileListProps) => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			window.scrollTo({ top: HEADER_HEIGHT, behavior: 'smooth' });
		}, 200);
		return () => {
			clearTimeout(timeout);
		};
	}, [formProps.values.activeCategoryName]);

	return (
		<div className={styles.clothingSelector__container}>
			{isLoading ? (
				<div className={styles.clothingSelector__activityIndicatorWrapper}>
					<ActivityIndicator />
				</div>
			) : (
				<div className={styles.clothingSelector__list}>
					{/* Animated Filters */}

					<CategoryFilter
						categories={categories}
						formProps={formProps}
						isSheetOpen={isSheetOpen}
						selectedCounts={selectedCounts}
						setIsSheetOpen={setIsSheetOpen}
					/>

					{/* Clothing Items List */}
					<div className={styles.clothingSelector__flexList}>
						{clothes?.map((cloth, index) => (
							<ClothingItemCard
								imageClassName={styles.clothingSelector__image}
								imageWrapperClassName={styles.myWardrobe__itemImageWrapper}
								isSelected={!!selectedClothes?.some((c) => c.name === cloth.name)}
								item={cloth}
								key={`${index}_${cloth.name}`}
								onSelect={onSelect}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export interface IMobileListProps {
	clothes: IClothing[];
	selectedClothes: IClothing[] | undefined;
	onSelect: (cloth: IClothing) => void;
	isSheetOpen: boolean;
	setIsSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedCounts: Record<string, number>;
	formProps: FormikProps<TDemoFormValues>;
	categories: ICategory[];
	isLoading: boolean;
}
